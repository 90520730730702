$main_color : #009988;
$main_color_light: #B2DFDB;
$second_color: #ffa81d;
$main_text_color: #bcc5c9;
$second_text_color: #757575;
$border_color: #393c4b;
$link_text_color: #bcc5c9;
$bg_color: #15181e;
$bg_color_dark: #0a0c11;
$bg_color_light: #25292f;//paper?

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-box-shadow: none;
  box-shadow: none;   
  outline: none;  
}

html {
  background-color:$bg_color;
  color:$main_text_color;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4 {
  color: #ffffff;
}

.root {
  padding:1em 0;
  padding-top: 4.5em;
  height:100%;
  box-sizing:border-box;
  position:relative;
  line-height: 1.5em;
  overflow-y:auto;
}

.miniRoot {
  padding:1em 0;
  padding-top: 2.5em;
  width: 100%;
  max-width:480px;
  margin:0 auto;
  box-sizing:border-box;
  line-height: 1.5em;
}

.smallRoot {
  padding:0em 0;
  width: 100%;
  max-width:600px;
  margin:0 auto;
  box-sizing:border-box;
  line-height: 1.5em;
}

.mediumRoot {
  padding:1em 1em;
  width: 100%;
  max-width:800px;
  margin:0 auto;
  box-sizing:border-box;
  line-height: 1.5em;
}

.modal {
  position:absolute;
  width:100%;
  height:100%;
  max-width:460px;
  max-height:620px;
  background-color:#111111;
  box-sizing:border-box;
}

.modalRoot {
  height:100%;
  box-sizing:border-box;
  overflow-x:hidden;
  overflow-y:auto;
  padding-top: 1em;
  line-height: 1.4em;
  padding-right: 1em;
  padding-left: 1em;
  border:1px solid #CCCCCC;
}

.modalHead {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  padding:0em;
  padding-top:0.1em;
  line-height:1em;
  box-sizing:border-box;
  background-color:#393F4F;
  z-index:999;
}

.dialogRoot {
  box-sizing:border-box;
  padding: 1em;
  border:1px solid #CCCCCC;
  h2 {
    font-size:120%;
    margin:0;
    padding:0;
    margin-bottom:1em;
  }
}

h2.withSearch {
  position:relative;
  div.goSearch {
    position:absolute;
    bottom:0;
    right:0;
    cursor:pointer;
    a {
      color:$main_color;
    }
  }
}

.alert {
  position:absolute;
  width:100%;
  max-width:240px;
  background-color:#CCCCCC;
  box-sizing:border-box;
  //filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.6));
}

.about {
  position:absolute;
  width:100%;
  max-width:300px;
  background-color:#FFFFFF;
  box-sizing:border-box;
  //filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.6));
  h2 {
    margin:0;
    margin-bottom:0.5em;
    font-size:100%;
    font-weight:normal;
    color:$second_color;
  }
  div.text {
    font-size:90%;
    margin-bottom:2em;
    p.copyright {
      text-align:center;
      margin-top:3em;
    }
  }
}

.bottomApp {
  position:fixed;
  bottom:1em;
  left:0;
  box-sizing:border-box;
  width:100%;
  padding:0 1em;
}

div.nextBtns {
  text-align:center;
  margin-top:1em;
}

.title {
  color:$main_color;
  margin-bottom:0.5em!important;
}

.withIcon {
  display:inline-flex;
  vertical-align:middle;
}

.clickable {
  cursor:pointer;
}

.wide {
  width:100%;
}

.formLabel {
  color: $second_color!important;
}

.paper {
  padding:2em 1em;
  padding-bottom:6em;
  background-color:white;
  border-radius:0.5em 0.5em 0 0;
  line-height:1.8em;
  font-size:90%;
}

.paperTitle {
  margin-bottom:2em;
}

.talk {
  margin-bottom:2em;
  p {
    margin-bottom:1em;
  }
}

.calendar {
  position:absolute;
  width:100%;
  min-width:300px;
  max-width:360px;
  padding-top:2em;
  background-color:#FFFFFF;
  box-sizing:border-box;
}

.profileInput {
  position:absolute;
  width:100%;
  min-width:300px;
  max-width:420px;
  padding-top:2em;
  background-color:#FFFFFF;
  box-sizing:border-box;
}

.base {
  position:relative;
}

.removeBtn {
  text-align:right;
  cursor:pointer;
  z-index:999;
}

div.colData {
  position:relative;
  div.name {
    background-color:#EEEEEE;
    padding:0 0.5em;
    font-weight:400;
  }
  div.value {
    padding:0;
    margin-bottom:1.5em;
  }
  div.btn {
    position:absolute;
    right:0;
    top:0;
  }
  div.name_w {
    background-color:#FFFFFF;
    padding:0;
    font-weight:700;
  }
  div.value_w {
    padding:0;
    margin-bottom:1em;
  }
}

div.profile {
  h2 {
    font-size:120%;
    border-bottom:1px dashed $border_color;
    padding:0;
    margin:0;
    margin-bottom:1em;
    padding-bottom:0.5em;
  }
}

div.search {
  border-top:1px dashed $border_color;
  padding-top:2em;
  background-color:white;
  border-radius:0 0 0.5em 0.5em;
  div.btns {
    text-align:center;
  }
}

.mailLoginInput {
  border:1px solid $border_color;
  border-radius:0.5em;
  padding:1em;
  margin-top:1em;
}

.hint {
  margin:1em 0;
  color:$second_color;
  font-size:90%;
}

.goMain {
  margin-top:1em;
  text-align:center;
}

.link {
  text-decoration:none;
  color: $link_text_color;
}

.first {
  padding:1em 0;
  margin-bottom:4em;
  h2 {
    color:$second_color;
    margin:0;
    margin-bottom:1em;
    font-size:100%;
    font-weight:normal;
  }
  p {
    line-height:1.7em;
    margin:0;
    margin-bottom:0.5em;
  }
  div.sample {
    text-align:center;
    margin-top:3em;
  }
}

div.view {
  h1 {
    font-size:240%;
    margin-top:2em;
    margin-bottom:2em;
    text-align:center;
    line-height:1.3em;
    span.icon {
      color:$main_color;
      margin-right:0.3em;
    }
  }

  h2 {
    font-size:180%;
  }
 
  
  div.foreword {
    line-height:2em;
    padding-bottom:4em;
    margin-bottom:4em;
    border-bottom:1px solid $border_color;
    div.lastUpdate {
      padding:1em;
      margin-top:1em;
      background-color:$bg_color_dark;
      font-size:90%;
      line-height:1.4em;
      span {
        color: $second_color;
      }
    }
  }

  div.contents {
    div.content {
      margin-bottom:4em;
      line-height:2em;
      div.text {
        padding-bottom:1em;
      }
      div.links {
        div.link {
          margin-bottom:1em;
          a {
            background-color:$bg_color_light;
            padding:0.5em 1.5em;
            border-radius:1em;
            color:#ffffff;
          }
          a:hover {
            color: $main_text_color;
          }
        }
      }
    }
  }

  div.historys {
    margin-bottom:5em;
    ul {
      margin:0;
      padding:0;
      margin-left:1.5em;
      li {
        span {
          margin-left:1em;
          font-size:90%;
          color:#666666;
        }
      }
    }
  }
}

div.menus {
  background-color: $bg_color_dark;
  //padding-bottom:4em;
  ul.menus {
    list-style:none;
    margin:0;
    padding:0;
    margin-bottom:2em;
    li {
      font-size:80%;
      margin-bottom:0.5em;
    }
  }
  div.copyright {
    border-top:1px solid $border_color;
    padding:1em 0;
    margin-top:4em;
    text-align:center;
    color:$main_text_color;
    font-size:90%;
  }
  div.panel {
    background-color:$bg_color;
    border-radius:0.5em;
    padding:1em;
    padding-bottom:2em;
    color:#ffffff;
    h4 {
      margin:0;
      margin-bottom:1em;
    }
  }
}