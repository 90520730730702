.appbar {
    background-color:#B2DFDB!important;
    .personButton {
        padding-right:0!important;
        padding-left:0!important;
    }
    div.menuPerson {
        text-align:right;
        position:absolute;
        right:0.5em;
    }
}

.toolbar {
    padding-left:0.5em!important;
}
